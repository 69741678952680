<template>
    <div>

        <PageHeader title="Fake Tracking Manager">
            <div class="actionBtns">
                <router-link to="/tracking/create" class="default simple _icon">Create tracking<i class="fas fa-plus"></i></router-link>
            </div>
        </PageHeader>

        
        <div class="wrapper">
            <Table class="itemTable" :cols="['Start date', 'Driver name', 'Truck #', 'Trailer #', '', '']">
                <div class="row" :key="key" v-for="(item, key) of items">
                    <div><a :href="`/tracking/track/${item._id}`" target="_blank">{{ getDateTime(item.item.start.startDate) }}</a></div>
                    <div>{{ item.item.driver }}</div>
                    <div>{{ item.item.truckNumber }}</div>
                    <div>{{ item.item.trailerNumber }}</div>
                    <div><router-link :to="`/tracking/edit/${item._id}`">Edit</router-link></div>
                    <div><a href="" @click.prevent="deleteTrackingById(item._id, key)" class="deleteBtn">Remove</a></div>
                </div>
            </Table>
        </div>


    </div>
</template>

<script>
import moment from 'moment';
    export default {
        data() {
            return {
                items: []
            }
        },
        methods: {
            getDateTime(value) {
                return moment(value).format("ddd D MMM hh:mm A")
            },
            getItems() {
                this.ajax('GetListOfTracking', {
                    url: '/tracking/myList',
                    method: 'get'
                }, (err, body) => {
                    if(!err) {
                        this.items = body;
                    }
                });
            },
            deleteTrackingById(id, key) {
                this.items.splice(key, 1);
                this.ajax('HandleDeleteTracking', {
                    url: '/tracking/' + id,
                    method: 'delete'
                }, (err, body) => {});
            }
        },
        mounted() {
            this.getItems();
        }
    }
</script>


<style lang="scss">
.itemTable {
    .row {
        grid-template-columns: repeat(2, minmax(0, 1fr)) 150px 150px 75px 75px !important;
    }
}
</style>

<style lang="scss" scoped>

.itemTable {
    .row {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr)) 150px 150px 75px 75px !important;
        height: 50px;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}

.editBtn {
    border: 0;
    background: transparent;
    width: 34px;
    height: 34px;
    padding: 0;
}

.deleteBtn {
    color: $error;
}
</style>